import React from 'react';
import '../styles/InfoSection.css'; // Asegúrate de que este archivo CSS se haya actualizado

const InfoSection = () => {
  return (
    <div className="info-section">
      <div className="column horarios" data-aos="zoom-in">
        <h2>Horarios de Atención</h2>
        <div className="puntos">
          {[...Array(10)].map((_, index) => (
            <span key={index}></span>
          ))}
        </div>
        <ul>
          <li>Lunes a Viernes: 8 AM - 8 PM</li>
          <li>Sábados: 8 AM - 6 PM</li>
          <li className="closed">Domingo: Cerrado</li>
        </ul>
      </div>
      <div className="column mapa">
        <div className="map-container" data-aos="zoom-in">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d897.994920309027!2d-108.98100673034972!3d25.80424439858285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ba298c0de90a67%3A0x46608d6901a6b59d!2sSpeakLand%20Centro%20de%20Idiomas!5e0!3m2!1sen!2smx!4v1712874051617!5m2!1sen!2smx"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
