import React, { useState } from 'react';
import '../styles/Prueba.css';
import FlipClock from './FlipClock.jsx';
import CountdownTimer from './CountdownTimer.jsx';

const publicUrl = process.env.PUBLIC_URL;

const quizData = [
    {
        imagen: `${publicUrl}/media/prueba/1.png`,
        question: 'My name _____ Jack.',
        options: ['is', 'are', 'am', 'were'],
        answer: 'is',
    },
    {
        imagen: `${publicUrl}/media/prueba/2.png`,
        question: 'How old _____ he?',
        options: ['is', 'are', 'am', 'were'],
        answer: 'is',
    },
    {
        imagen: `${publicUrl}/media/prueba/3.png`,
        question: 'What _____ your phone number?',
        options: ['is', 'are', 'she', 'he'],
        answer: 'is',
    },
    {
        imagen: `${publicUrl}/media/prueba/4.png`,
        question: 'My phone is _____ my hand.',
        options: ['between', 'in', 'next', 'after'],
        answer: 'in',
    },
    {
        imagen: `${publicUrl}/media/prueba/5.png`,
        question: 'Your house is _____ mine.',
        options: [
            'between',
            'on',
            'next to',
            'inside',
        ],
        answer: 'next to',
    },
    {
        imagen: `${publicUrl}/media/prueba/6.png`,
        question: '_____ is your birthday?',
        options: ['What', 'Who', 'When', 'Which'],
        answer: 'When',
    },
    {
        imagen: `${publicUrl}/media/prueba/7.png`,
        question: 'Mike is from Germany. _____ house is in Frankfurt.',
        options: [
            'Her',
            'Our',
            'His',
            'Their',
        ],
        answer: 'His',
    },
    {
        imagen: `${publicUrl}/media/prueba/8.png`,
        question: 'They are Tina and Mark. _____ son is James.',
        options: ['My', 'His', 'Their', 'Our'],
        answer: 'Their',
    },
    {
        imagen: `${publicUrl}/media/prueba/9.png`,
        question: 'There _____ 2 books.',
        options: [
            'is',
            'are',
            'it',
            'will',
        ],
        answer: 'are',
    },
    {
        imagen: `${publicUrl}/media/prueba/10.png`,
        question: 'The cat is _____ than the mouse.',
        options: ['big', 'bigger', 'biggest', 'biggerer'],
        answer: 'bigger',
    },
    {
        imagen: `${publicUrl}/media/prueba/11.png`,
        question: 'The rabbit is _____ than the turtle.',
        options: ['fast', 'faster', 'fastest', 'fasting'],
        answer: 'faster',
    },
    {
        imagen: `${publicUrl}/media/prueba/12.png`,
        question: 'Select the past tense of the verb "walk".',
        options: ['walks', 'walked', 'walking', 'will walk'],
        answer: 'walked',
    },
    {
        imagen: `${publicUrl}/media/prueba/13.png`,
        question: 'Select the past tense of the verb "fall".',
        options: ['falled', 'fall', 'fell', 'falling'],
        answer: 'fell',
    },
    {
        imagen: `${publicUrl}/media/prueba/14.png`,
        question: 'Melany _____ to school on Saturdays.',
        options: ["don't goes", 'not go', "doesn't go", "doesn't going"],
        answer: "doesn't go",
    },
    {
        imagen: `${publicUrl}/media/prueba/15.png`,
        question: 'How _____ have you completed the homework?',
        options: ['far', 'was', 'big', 'long'],
        answer: 'far',
    },
    {
        imagen: `${publicUrl}/media/prueba/16.png`,
        question: 'I _____ started yet.',
        options: ["haven't", 'am not', "don't", "didn't"],
        answer: "haven't",
    },
    {
        imagen: `${publicUrl}/media/prueba/17.png`,
        question: 'Did she _____ her kid to school?',
        options: ['go', 'take', 'left', 'leave'],
        answer: 'take',
    },
    {
        imagen: `${publicUrl}/media/prueba/18.png`,
        question: 'What _____ of music do you listen ___?',
        options: ['kind, to', 'form, with', 'is, too', 'sound, at'],
        answer: 'kind, to',
    },
    {
        imagen: `${publicUrl}/media/prueba/19.png`,
        question: 'If you ____ broke, you must work from scratch.',
        options: ['end', 'go', 'be', 'can'],
        answer: 'go',
    },
    {
        imagen: `${publicUrl}/media/prueba/20.png`,
        question: 'If you need help, _____ hesitate.',
        options: ['please', 'do', "don't", 'make'],
        answer: "don't",
    },
    {
        imagen: `${publicUrl}/media/prueba/21.png`,
        question: 'Frank wishes he ____ more time ____ his family.',
        options: ['had, for', 'had, because', 'had, be', 'is, like'],
        answer: 'had, for',
    },
    {
        imagen: `${publicUrl}/media/prueba/22.png`,
        question: 'There _____ much to do. Move on!',
        options: ['nothing', 'are', "ain't", 'so'],
        answer: "ain't",
    },
    {
        imagen: `${publicUrl}/media/prueba/23.png`,
        question: 'To whom it _____ concern.',
        options: ['may', 'will', 'can', "can't"],
        answer: 'may',
    },
    {
        imagen: `${publicUrl}/media/prueba/24.png`,
        question: 'Please _____ out the cigarette.',
        options: ['turn', 'put', 'leave', 'give'],
        answer: 'put',
    },
    {
        imagen: `${publicUrl}/media/prueba/25.png`,
        question: 'I _____ never done this before. It was great! ',
        options: ['have', 'was', 'had', 'have not'],
        answer: 'had',
    },
];


const Prueba = () => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [isAnswered, setIsAnswered] = useState(false);
    const [score, setScore] = useState(0);
    const [isQuizFinished, setIsQuizFinished] = useState(false);
    const [showCountdown, setShowCountdown] = useState(true);
    const [message, setMessage] = useState(''); // Define el estado `message`

    const handleCountdownEnd = () => {
        setShowCountdown(false);
    };

    const handleOptionClick = (option) => {
        if (!isAnswered) {
            setSelectedOption(option);
        }
    };

    const handleContinueOrNext = () => {
        if (!isAnswered) {
            if (selectedOption === '') {
                setMessage('Por favor, selecciona una opción antes de continuar.');
            } else {
                setMessage('');
                setIsAnswered(true);
                if (selectedOption === quizData[currentQuestion].answer) {
                    setScore((prevScore) => prevScore + 1);
                }
            }
        } else {
            if (currentQuestion + 1 < quizData.length) {
                setSelectedOption('');
                setCurrentQuestion((prev) => prev + 1);
                setIsAnswered(false);
                setMessage('');
            } else {
                setIsQuizFinished(true);
            }
        }
    };

    const handleContactClick = () => {
        const message = `Hola, hice el exam y este fue mi score: ${score}, estoy interesado en saber mas sobre sus cursos`;
        const whatsappUrl = `https://api.whatsapp.com/send?phone=5216681931693&text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
    };

    const handleRetry = () => {
        setCurrentQuestion(0);
        setSelectedOption('');
        setIsAnswered(false);
        setScore(0);
        setIsQuizFinished(false);
        setMessage('');
    };

    const getOptionClass = (option) => {
        if (isAnswered) {
            if (option === quizData[currentQuestion].answer) {
                return 'correct';
            } else {
                return selectedOption === option ? 'selected incorrect' : 'incorrect';
            }
        } else if (selectedOption === option) {
            return 'selected';
        } else {
            return '';
        }
    };

    const getContainerClass = () => {
        if (isAnswered) {
            return selectedOption === quizData[currentQuestion].answer ? 'correct' : 'incorrect';
        }
        return '';
    };

    const getScoreMessage = () => {
        if (score === 25) {
            return '¡Excelente trabajo! ¡Obtuviste la puntuación perfecta!';
        } else if (score >= 20) {
            return '¡Muy bien hecho! Casi perfecto.';
        } else if (score >= 15) {
            return 'Buen trabajo, pero puedes mejorar.';
        } else if (score >= 10) {
            return 'Estás en camino, sigue practicando.';
        } else {
            return 'No te desanimes, sigue intentándolo.';
        }
    };

    if (isQuizFinished) {
        return (
            <div className="quiz-results">
                <div className="quiz-results-header">
                    <h2>Resultados</h2>
                </div>
                <div className="quiz-results-score">
                    <p className="quiz-score-text">
                        You scored <span className="quiz-score">{score}</span> <span className="slash">/</span> <span className="quiz-total">{quizData.length}</span><span className="exclamation">!</span>
                    </p>
                    <p className="quiz-message">{getScoreMessage()}</p>
                </div>
                <div className="quiz-results-timer">
                    <FlipClock />
                </div>
                <div className="quiz-results-promotion">
                    <p className="promotion-text">
                        ¡Aprovecha un <img src={`${publicUrl}/media/50descuento.png`} alt="Descuento del 50%" className="promotion-image" /> en tu inscripción dando clic al siguiente botón!
                    </p>
                </div>
                <div className="quiz-results-contact">
                    <button className="quiz-contact-button" onClick={handleContactClick}>
                        <img src={`${publicUrl}/media/logo/wpp.png`} alt="WhatsApp" />
                        Contáctanos ahora
                    </button>
                </div>
            </div>
        );
    }

    if (showCountdown) {
        return <CountdownTimer onCountdownEnd={handleCountdownEnd} />;
    }

    const questionData = quizData[currentQuestion];

    return (
        <div className={`quiz-container ${getContainerClass()}`}>
            <div className="quiz-message-container">
                {message && <p className="quiz-message">{message}</p>}
            </div>
            <h2 className="quiz-question">{questionData.question}</h2>
            <p className="quiz-number">Pregunta {currentQuestion + 1} de {quizData.length}</p>
            <div className="quiz-image-container">
                <img src={questionData.imagen} alt="Pregunta" className="quiz-image" />
            </div>
            <div className="quiz-options">
                {questionData.options.map((option, index) => (
                    <button
                        key={index}
                        className={`quiz-option ${getOptionClass(option)}`}
                        onClick={() => handleOptionClick(option)}
                        disabled={isAnswered}
                    >
                        {option}
                    </button>
                ))}
            </div>
            <div className="quiz-buttons">
                <button className="quiz-submit" onClick={handleContinueOrNext}>
                    {isAnswered ? (currentQuestion + 1 < quizData.length ? 'Next' : 'Finish') : 'Submit'}
                </button>
            </div>
        </div>
    );
};

export default Prueba;

