import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../styles/Navbar.css';

const Navbar = ({ scrolling }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleNavClick = (sectionId) => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    } else {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
    setMenuOpen(false);
  };

  const handleLinkClick = (path) => {
    navigate(path);
    setMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className={`navbar ${scrolling ? 'scrolled' : ''}`} ref={menuRef}>
      <div className="navbar-logo">
        <a href="/">
          <img className="logo" src={`${process.env.PUBLIC_URL}/media/logo.png`} alt="Logo" />
        </a>
      </div>
      <button className="navbar-toggle" onClick={toggleMenu}>
        <span className={`navbar-toggle-icon ${menuOpen ? 'active' : ''}`}></span>
      </button>
      <div className={`navbar-links ${menuOpen ? 'active' : ''}`}>
        <ul>
          <li><Link to="/" className="scroll-link" onClick={() => handleNavClick('home')}>Inicio</Link></li>
          <li><Link to="/courses" className="scroll-link" onClick={() => handleLinkClick('/courses')}>Cursos</Link></li>
          <li><Link to="/" className="scroll-link" onClick={() => handleNavClick('certificaciones')}>Certificaciones</Link></li>
          <li><Link to="/" className="scroll-link" onClick={() => handleNavClick('alianzas')}>Alianzas</Link></li>
          <li><Link to="/" className="scroll-link" onClick={() => handleNavClick('contact')}>Contacto</Link></li>
          <li><Link to="/staff" className="scroll-link" onClick={() => handleLinkClick('/staff')}>Staff</Link></li>
          <li><Link to="/bolsatrabajo" className="scroll-link" onClick={() => handleLinkClick('/bolsatrabajo')}>Bolsa de Trabajo</Link></li>
          <li><Link to="/prueba" className="scroll-link" onClick={() => handleLinkClick('/prueba')}>Exam</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
