import React from 'react';
import '../styles/OpinionesSection.css'; // Asegúrate de crear este archivo para los estilos

const OpinionesSection = () => {
  const publicUrl = process.env.PUBLIC_URL;
  const opiniones = [
    {
      name: 'José Luis Olivo',
      image: `${publicUrl}/media/reviews_img/JLO.PNG`,
      stars: 5,
      text: "La mejor experiencia de aprendizaje que he tenido. Los profesores son increíbles y el ambiente es muy amigable."
    },
    {
      name: 'Anahí Delgado',
      image: `${publicUrl}/media/reviews_img/anahi.jpeg`,
      stars: 5,
      text: "Un curso muy completo y bien estructurado. Me ayudó a mejorar mi inglés de manera significativa."
    },
    {
      name: 'Daniel Rojas Hull',
      image: `${publicUrl}/media/reviews_img/DARH.PNG`,
      stars: 5,
      text: "Las clases son muy dinámicas y el contenido es siempre relevante. Recomiendo esta escuela a todos mis amigos."
    },
    {
      name: 'Ale Navarro',
      image: `${publicUrl}/media/reviews_img/ale.jpeg`,
      stars: 5,
      text: "Una experiencia única. Los recursos disponibles y el apoyo de los maestros son excepcionales."
    }
  ];

  const renderStars = (count) => {
    return Array.from({ length: 5 }, (_, index) => (
      <span key={index} className={`star ${index < count ? 'filled' : ''}`}>★</span>
    ));
  };

  return (
    <div className="opiniones-section">
      <div className="header-container" data-aos="zoom-in">
        <img
          src={`${publicUrl}/media/encabezados/opiniones.png`}
          alt="Speakfest 2024"
          className="header-image"
        />
      </div>
      <div className="opiniones-container">
        {opiniones.map((opinion, index) => (
          <div className="opinion" key={index} data-aos="zoom-in" data-aos-delay="350">
            <div className="opinion-header">
              <img src={opinion.image} alt={`Estudiante ${index + 1}`} className="student-image" />
              <div className="rating">
                {renderStars(opinion.stars)}
              </div>
            </div>
            <h3 className="student-name">{opinion.name}</h3>
            <p className="opinion-text">{opinion.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OpinionesSection;
