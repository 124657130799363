import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import '../styles/BolsaTrabajo.css';

const publicUrl = process.env.PUBLIC_URL;

const BolsaTrabajo = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false,
            mirror: true,
        });

        const cards = document.querySelectorAll('.job-card');

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio >= 0.5 && entry.isIntersecting) {
                        entry.target.classList.remove('fade-out');
                    } else if (entry.intersectionRatio < 0.5) {
                        entry.target.classList.add('fade-out');
                    }
                });
            },
            {
                threshold: [0, 0.5, 1],
            }
        );

        cards.forEach((card) => observer.observe(card));

        return () => observer.disconnect();
    }, []);

    const jobPositions = [
        {
            title: 'DISEÑO',
            description: 'Se parte de nuestro equipo creativo y creación de contenido explotando tus talentos.',
            image: `${publicUrl}/media/trabajo/disenador.png`,
            subject: 'VACANTE DISEÑO'
        },
        {
            title: 'ADMINISTRACIÓN',
            description: 'Se parte de nuestro equipo de cobranza, verificación, control escolar y más cargos administrativos.',
            image: `${publicUrl}/media/trabajo/administradora.png`,
            subject: 'VACANTE ADMINISTRACION'
        },
        {
            title: 'DOCENTES',
            description: 'Se parte de nuestro gran equipo docente para explotar tus habilidades en la enseñanza de cualquier idioma que domines.',
            image: `${publicUrl}/media/trabajo/teacher.png`,
            subject: 'VACANTE DOCENTES'
        },
        {
            title: 'STAFF',
            description: 'Se parte de nuestro equipo de apoyo como teacher aide.',
            image: `${publicUrl}/media/trabajo/aid.png`,
            subject: 'VACANTE STAFF'
        },
        {
            title: 'EXTERNOS',
            description: 'Se parte de las empresas locales con mayor oferta y necesidad de personal bilingüe mediante nuestras alianzas exclusivas laborales.',
            image: `${publicUrl}/media/trabajo/empresa.png`,
            subject: 'VACANTE EXTERNOS'
        },
        {
            title: 'VENTAS',
            description: 'Si eres un berraco en ventas, contáctanos. Solo para berracos, no chickens.',
            image: `${publicUrl}/media/trabajo/salesperson.png`,
            subject: 'VACANTE VENTAS'
        },
    ];

    return (
        <div className="job-container">
            <img
                src={`${publicUrl}/media/encabezados/bolsa.png`}
                alt="Speakfest 2024"
                className="header-image"
                data-aos="zoom-in"
            />
            <div className="job-grid">
                {jobPositions.map((job, index) => (
                    <div 
                        key={index} 
                        className="job-card" 
                    >
                        <img src={job.image} alt={job.title} className="job-image" />
                        <div className="job-info">
                            <h3>{job.title}</h3>
                            <p>{job.description}</p>
                            <a className="cv-button" target="_blank" rel="noopener noreferrer" href={`mailto:contacto@speakland.com.mx?subject=${job.subject}&body=¡Hola! Me interesa la vacante, adjunto mi cv a continuación`}>
                                Enviar CV
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BolsaTrabajo;
