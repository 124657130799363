// src/components/Staff.js
import React, { useState } from 'react';
import '../styles/Staff.css';

const publicUrl = process.env.PUBLIC_URL;

const infoStaffDetails = {
    'Manny Lastras': {
        titulo: 'Lic. Manuel Lastras',
        contenido: `
            <hr>
            <p>🎓Licenciatura en Docencia en el idioma inglés</p>
            <p>👨🏻‍🏫Especialidad en Adultos CELTA</p>
            <p>👨🏻‍🏫Diplomado DELTE</p>
            <p>📚NIVEL <b>C1</b></p>
            <p>Capacitador de TKT,PET,KET,FLYERS,TOEFL</p>
            <p>Cuenta con TKT 1,2,3</p>
            <p>Años de experiencia: 20</p>
            <p>💼Cargo en Speakland: Director General</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/manny.png`
    },
    'Jerry Cámara': {
        titulo: 'Lic. Gerardo García Cámara',
        contenido: `
            <hr>
            <p>🎓Comunicación y Administración Corporativa</p>
            <p>Coach AICTRAP (Asociación Internacional de Coaches en Transformación Personal)</p>
            <p>📃PET</p>
            <p>📚NIVEL <b>C1</b></p>
            <p>👨🏻‍🏫Teacher de grupos personalizados y conversacionales avanzados</p>
            <p>Años de experiencia: 10</p>
            <p>💼Cargo en Speakland: Teacher / Vinculación y publicidad</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/jerry.png`
    },
    'Gerardo García': {
        titulo: 'Lic. Gerardo García Miguel',
        contenido: `
            <hr>
            <p>🎓Licenciatura en Sistemas Computacionales</p>
            <p>📚NIVEL <b>C1</b></p>
            <p>CENNI 15</p>
            <p>TKT 1,2,3</p>
            <p>📃CAE</p>
            <p>Años de experiencia: 14</p>
            <p>💼Cargo en Speakland: Teacher de niveles avanzados</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/gerardo.jpeg`
    },
    'Lupita Flores': {
        titulo: 'Lic. Guadalupe Susana Flores Dominguez',
        contenido: `
            <hr>
            <p>🎓Lic. Formación Docente tipo medio Superior énfasis Inglés</p> 
            <p>CENNI <b>10</b></p>
            <p>TKT 1,2,3</p>
            <p>ITEP</p>
            <p>Aptis</p>
            <p>Nivel <b>B1</b></p>
            <p>Diplomado en Metodología en la Enseñanza del idioma Inglés (CAM)</p>
            <p>Años de experiencia: 5</p>
            <p>💼Cargo en Speakland: Teacher para niños</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/lupita.png`
    },
    'Alejandra Valdez': {
        titulo: 'Lic. Zulema Alejandra Valdez Guillen',
        contenido: `
            <hr>
            <p>🎓Licenciatura en Enseñanza del Idioma Inglés</p>
            <p>🎓Lic. en enseñanza del idioma inglés</p>
            <p>CENNI <b>12</b></p>
            <p>TKT 1,2,3</p>
            <p>TKT young learners <b>C1</b></p>
            <p>Diplomado DELTE <b>B2</b></p>
            <p>Nivel <b>B2</b></p>
            <p>Años de experiencia: 8</p>
            <p>💼Cargo en Speakland: Teacher para niños y adultos</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/alejandra.png`
    },
    'Cielo Berrelleza': {
        titulo: 'Lic. Cielo Anahí Berrelleza',
        contenido: `
            <hr>
            <p>🎓Licenciatura en Intervención Educativa Inclusiva</p>
            <p>📃PET</p>
            <p>📚NIVEL <b>B1</b></p>
            <p>Años de experiencia: 2</p>
            <p>💼Cargo en Speakland: Teacher para niños</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/cielo.jpeg`
    },
    'Paul Salas': {
        titulo: 'M.I. Paul R. Salas Hernández',
        contenido: `
            <hr>
            <p>🎓Maestría en Ingeniería: Gestión ambiental y eficiencia energética</p>
            <p>📃PET</p>
            <p>📚NIVEL <b>B2</b></p>
            <p>Años de experiencia: 3</p>
            <p>💼Cargo en Speakland: Supervisor académico</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/paul.jpeg`
    },
    'Ariadna Espinoza': {
        titulo: 'Ariadna Espinoza',
        contenido: `
            <hr>
            <p>Estudiante de Licenciatura en Enseñanza del Idioma Inglés</p>
            <p>Especialidad en young learners</p>
            <p>📚NIVEL <b>B2</b></p>
            <p>Años de experiencia: 3</p>
            <p>💼Cargo en Speakland: Teacher para niños</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/ariadna.jpeg`
    },
    'Edgar Dorantes': {
        titulo: 'Lic. Edgar Ricardo Dorantes Vértiz',
        contenido: `
            <hr>
            <p>🎓Licenciatura en Comunicación</p>
            <p>🎓Maestro en Educación en Idioma Inglés</p>
            <p>📃PET</p>
            <p>📚NIVEL <b>C1</b></p>
            <p>TKT 1,2,3</p>
            <p>EILTS</p>
            <p>Años de experiencia: 22</p>
            <p>💼Cargo en Speakland: Teacher de adultos preparación PET</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/edgar.jpeg`
    },
    'José Gil': {
        titulo: 'Ing. José Erubiel Gil Chaparro ',
        contenido: `
            <hr>
            <p>🎓Ingeniería industrial </p>
            <p>📚NIVEL <b>B2</b></p>
            <p>FCE</p>
            <p>Años de experiencia: 2</p>
            <p>💼Cargo en Speakland: Teacher para niños y adultos</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/gil.png`
    },
    'Alejandra Rubio': {
        titulo: 'Lic. Alejandra Miranda Rubio ',
        contenido: `
            <hr>
            <p>🎓Lic en educación</p>
            <p>📚NIVEL <b>B2</b></p>
            <p>IELTS</p>
            <p>TKT 1,2,3</p>
            <p>Años de experiencia: 5</p>
            <p>💼Cargo en Speakland: Teacher para niños</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/miranda.png`
    },
    'Mariajosé Avena': {
        titulo: 'María José Avena Martínez',
        contenido: `
            <hr>
            <p>Estudiante de preparatoria</p>
            <p>📃PET</p>
            <p>📚NIVEL <b>B1</b></p>
            <p>💼Cargo en Speakland: Teacher Aide</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/avena.png`
    }
};

const Staff = () => {
    const [selectedMember, setSelectedMember] = useState(null);

    const handleCardClick = (name) => {
        setSelectedMember(name);
    };

    const handleCloseModal = () => {
        setSelectedMember(null);
    };

    const handleModalClick = (e) => {
        if (e.target === e.currentTarget) {
            handleCloseModal();
        }
    };

    return (
        <div className="staff-container">
            <div className="staff-background" data-aos="zoom-in">
                <div className="staff-overlay">
                    <h1 className="staff-title">CONOCE A NUESTRO STAFF</h1>
                </div>
            </div>
            <p className="staff-subtitle" data-aos="zoom-in">
                - We are a group of innovating, experienced, and proficient teachers you will love to learn from, we also think that <br /> the service is more important than the sale. -
            </p>

            <h2 className="staff-section-title" data-aos="fade-up">Miembros administrativos / Admin Staff</h2>
            <div className="staff-row" data-aos="zoom-in">
                <div className="staff-card" onClick={() => handleCardClick('Manny Lastras')}>
                    <img src={`${publicUrl}/media/staff_img/manny.png`} alt="Manny Lastras" className="staff-image" />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Manny</h3>
                        <h4 className="staff-card-surname">Lastras</h4>
                        <p className="staff-card-role"><b>CEO</b></p>
                    </div>
                </div>

                <div className="staff-card" onClick={() => handleCardClick('Paul Salas')}>
                    <img src={`${publicUrl}/media/staff_img/paul.jpeg`} alt="Paul Salas" className="staff-image" />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Paul</h3>
                        <h4 className="staff-card-surname">Salas</h4>
                        <p className="staff-card-role"><b>Supervisor académico</b></p>
                    </div>
                </div>
            </div>

            <h2 className="staff-section-title" data-aos="fade-up">Docentes / Teachers</h2>
            <div className="staff-row" data-aos="zoom-in">
                <div className="staff-card" onClick={() => handleCardClick('Jerry Cámara')}>
                    <img src={`${publicUrl}/media/staff_img/jerry.png`} alt="Jerry Cámara" className="staff-image" />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Jerry</h3>
                        <h4 className="staff-card-surname">Cámara</h4>
                        <p className="staff-card-role"><b>Teacher</b></p>
                    </div>
                </div>

                <div className="staff-card" onClick={() => handleCardClick('Gerardo García')}>
                    <img src={`${publicUrl}/media/staff_img/gerardo.jpeg`} alt="Gerardo García" className="staff-image" />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Gerardo</h3>
                        <h4 className="staff-card-surname">García</h4>
                        <p className="staff-card-role"><b>Teacher</b></p>
                    </div>
                </div>

                <div className="staff-card" onClick={() => handleCardClick('Ariadna Espinoza')}>
                    <img src={`${publicUrl}/media/staff_img/ariadna.jpeg`} alt="Ariadna Espinoza" className="staff-image" />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Ariadna</h3>
                        <h4 className="staff-card-surname">Espinoza</h4>
                        <p className="staff-card-role"><b>Teacher</b></p>
                    </div>
                </div>
            </div>

            <div className="staff-row" data-aos="zoom-in">
                <div className="staff-card" onClick={() => handleCardClick('Edgar Dorantes')}>
                    <img src={`${publicUrl}/media/staff_img/edgar.jpeg`} alt="Edgar Dorantes" className="staff-image" />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Edgar</h3>
                        <h4 className="staff-card-surname">Dorantes</h4>
                        <p className="staff-card-role"><b>Teacher</b></p>
                    </div>
                </div>

                <div className="staff-card" onClick={() => handleCardClick('José Gil')}>
                    <img src={`${publicUrl}/media/staff_img/gil.png`} alt="José Gil" className="staff-image" />
                    <div className="staff-info">
                        <h3 className="staff-card-name">José</h3>
                        <h4 className="staff-card-surname">Gil</h4>
                        <p className="staff-card-role"><b>Teacher</b></p>
                    </div>
                </div>

                <div className="staff-card" onClick={() => handleCardClick('Alejandra Rubio')}>
                    <img src={`${publicUrl}/media/staff_img/miranda.png`} alt="Alejandra Rubio" className="staff-image" />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Alejandra</h3>
                        <h4 className="staff-card-surname">Rubio</h4>
                        <p className="staff-card-role"><b>Teacher</b></p>
                    </div>
                </div>
            </div>

            <h2 className="staff-section-title" data-aos="fade-up">Auxiliar docente / Teacher's AIDE</h2>
            <div className="staff-row" data-aos="zoom-in">
                <div className="staff-card" onClick={() => handleCardClick('Mariajosé Avena')}>
                    <img src={`${publicUrl}/media/staff_img/avena.png`} alt="Mariajosé Avena" className="staff-image" />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Mariajosé</h3>
                        <h4 className="staff-card-surname">Avena</h4>
                        <p className="staff-card-role"><b>Teacher Aide</b></p>
                    </div>
                </div>
            </div>


            {selectedMember !== null && infoStaffDetails[selectedMember] && (
                <div
                    className="staff-modal"
                    onClick={handleModalClick} // Manejador de clic para el fondo del modal
                >
                    <div className="staff-modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="staff-modal-close" onClick={handleCloseModal}>&times;</span>
                        <img
                            src={infoStaffDetails[selectedMember].imagenUrl}
                            alt={infoStaffDetails[selectedMember].titulo}
                            className="staff-modal-img"
                        />
                        <h2 className="staff-modal-title">{infoStaffDetails[selectedMember].titulo}</h2>
                        <div
                            className="staff-modal-content-text"
                            dangerouslySetInnerHTML={{ __html: infoStaffDetails[selectedMember].contenido }}
                        />
                    </div>
                </div>
            )}

        </div>
    );

};

export default Staff;