import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import '../index.css';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import '../styles/FlipClock.css'; // Asegúrate de que este archivo se importe correctamente

const FlipClock = () => {
    const endTime = new Date().getTime() + 12 * 3600 * 1000; // 12 horas desde ahora

    return (
        <div className="flip-clock-container">
            <section className="flex flex-col justify-center items-center h-screen w-screen">
                <FlipClockCountdown
                    className='flip-clock'
                    to={endTime}
                    labels={['HOURS', 'MINUTES', 'SECONDS']}
                    duration={0.5}
                />
            </section>
        </div>
    );
}

export default FlipClock;
