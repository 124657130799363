import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import ImageSlider from './components/ImageSlider';
import SpeakfestSection from './components/SpeakfestSection';
import EventosEspeciales from './components/EventosEspecialesSection';
import Certificaciones from './components/CertificacionesSection';
import OpinionesSection from './components/OpinionesSection';
import InfoSection from './components/InfoSection';
import FooterSection from './components/FooterSection';
import Prueba from './components/Prueba';
import './styles/App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Staff from './components/Staff';
import BolsaTrabajo from './components/BolsaTrabajo';
import 'bootstrap/dist/css/bootstrap.min.css';
import CoursesSection from './components/CoursesSection';
import ScrollToTop from './components/ScrollToTop'; 
import ScrollIndicator from './components/ScrollIndicator';
import WhatsAppButton from './components/WhatsAppButton';
import Alianzas from './components/alianzas'; 
import Popup from './components/Popup';

AOS.init();

const App = () => {
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    setScrolling(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Navbar scrolling={scrolling} />
      <div className="app-content">
        <Popup />
        <ScrollIndicator />
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <div id="home"><ImageSlider /></div>
                <div id="speakfest"><SpeakfestSection /></div>
                <div id="eventos"><EventosEspeciales /></div>
                <div id="certificaciones"><Certificaciones /></div>
                <div id="opinions"><OpinionesSection /></div>
                <div id="alianzas"><Alianzas /></div>
                <div id="info"><InfoSection /></div>
                <div id="contact"><FooterSection /></div>
              </>
            } 
          />
          <Route path="/courses" element={<CoursesSection />} />
          <Route path="/prueba" element={<Prueba />} />
          <Route path="/staff" element={<Staff />} />
          <Route path="/bolsatrabajo" element={<BolsaTrabajo />} />

          <Route path="*" element={<Navigate to="/" />} /> {/* Redirige cualquier ruta no definida a la página de inicio */}
        </Routes>
        <WhatsAppButton />
      </div>
    </Router>
  );
};

export default App;
