import React from 'react';
import '../styles/CertificacionesSection.css'; // Asegúrate de crear este archivo para los estilos

const Certificaciones = () => {
  const publicUrl = process.env.PUBLIC_URL;
  const certifications = [
    { front: `${publicUrl}/media/certificates_img/flyers.png`, back: `${publicUrl}/media/certificates_img/A2.png` },
    { front: `${publicUrl}/media/certificates_img/ket.png`, back: `${publicUrl}/media/certificates_img/B1.png` },
    { front: `${publicUrl}/media/certificates_img/pet.png`, back: `${publicUrl}/media/certificates_img/B2.png` },
    { front: `${publicUrl}/media/certificates_img/fce.png`, back: `${publicUrl}/media/certificates_img/C1.png` },
    { front: `${publicUrl}/media/certificates_img/toefl.png`, back: `${publicUrl}/media/certificates_img/C2.png` },
    { front: `${publicUrl}/media/certificates_img/tkt.png`, back: `${publicUrl}/media/certificates_img/TKTtarjeta.png`} // Sin imagen trasera
  ];

  return (
    <div className="certifications-section">
      <div className="certifications-header">
        <img
          src={`${publicUrl}/media/encabezados/certificados.png`}
          alt="Speakfest 2024"
          className="header-image"
        />
      </div>
      <div className="certifications-cards">
        {certifications.map((cert, index) => (
          <div
            className={`certification-card ${cert.back ? '' : 'no-back'}`}
            key={index}
            data-aos="zoom-in"
            data-aos-delay="350"
          >
            <div className="card-inner">
              <div className="card-front">
                <img src={cert.front} alt={`Certificación ${index + 1} Front`} />
              </div>
              {cert.back && (
                <div className="card-back">
                  <img src={cert.back} alt={`Certificación ${index + 1} Back`} />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Certificaciones;
