// src/components/ScrollIndicator.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Importa el hook de React Router para detectar cambios en la ruta
import '../styles/ScrollIndicator.css'; // Importa el archivo CSS para los estilos

const ScrollIndicator = () => {
  // Definir las secciones en el orden en el que deseas navegar
  const sections = ['home', 'speakfest', 'eventos', 'certificaciones', 'opinions', 'info', 'contact'];
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [shouldScroll, setShouldScroll] = useState(true); // Estado para controlar si debe aparecer el scroll
  const [smallScroll, setSmallScroll] = useState(false); // Estado para activar scroll pequeño

  const location = useLocation(); // Hook para obtener la ruta actual

  // Efecto para manejar el comportamiento basado en la ruta actual
  useEffect(() => {
    const currentPath = location.pathname;

    // Simulación de scroll pequeño solo para 'courses', 'staff', 'bolsatrabajo'
    if (['/courses', '/staff', '/bolsatrabajo'].includes(currentPath)) {
      setShouldScroll(true);
      setSmallScroll(true); // Activar scroll pequeño
    } 
    // Desactivar el scroll para 'prueba'
    else if (currentPath === '/prueba') {
      setShouldScroll(false); // No mostrar el scroll
      setSmallScroll(false); // Asegurar que el scroll pequeño también esté desactivado
    } else {
      setShouldScroll(true); // Para otras rutas, mantener el comportamiento original
      setSmallScroll(false); // Scroll normal en otras secciones
    }
  }, [location.pathname]); // Reaccionar a los cambios de ruta

  // Función para desplazarse a la sección siguiente o hacer un pequeño scroll hacia abajo
  const scrollToNextSection = () => {
    if (shouldScroll) {
      if (smallScroll) {
        // Scroll hacia abajo solo un poco (simulación)
        window.scrollBy({ top: 500, behavior: 'smooth' }); // Ajusta el valor de 'top' para controlar el tamaño del scroll
      } else {
        // Desplazarse a la siguiente sección normalmente
        const nextIndex = (currentSectionIndex + 1) % sections.length;
        const sectionId = sections[nextIndex];
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
          setCurrentSectionIndex(nextIndex); // Actualizar el índice de la sección actual
        }
      }
    }
  };

  return (
    shouldScroll && (
      <div className="scroll-indicator" onClick={scrollToNextSection}>
        <div className="scroll-indicator-icon">╲╱</div>
        <div className="scroll-indicator-icon">╲╱</div>
      </div>
    )
  );
};

export default ScrollIndicator;
