import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/FooterSection.css';

const FooterSection = () => {
  const publicUrl = process.env.PUBLIC_URL;

  return (
    <div className="footer-section">
      <div className="footer-content">
        {/* Primera fila: Contacto, Servicios y Menú */}
        <div className="footer-column">
          <h2>Contáctanos</h2>
          <p>
            Calle I. Allende #1468  Local #1 esq. con Blvd. 10 de mayo. <br /> Fracc. Las Fuentes, Los Mochis, México <br /> CP 81223
          </p>
          <p>
            <a href="https://api.whatsapp.com/send?phone=5216681931693&text=Hola%2C%20quiero%20informaci%C3%B3n%20acerca%20de%20los%20cursos" className="contact-link" target="_blank" rel="noopener noreferrer">
              <img src={`${publicUrl}/media/logo/wpp.png`} alt="Whatsapp" className="contact-icon" />
              +52 1 668 193 1693
            </a>
          </p>
          <p>
            <a href="tel:+5216682390450" className="contact-link">
              <img src={`${publicUrl}/media/logo/phone.png`} alt="Teléfono" className="contact-icon" />
              +52 1 668 239 0450
            </a>
          </p>
          <a href="mailto:contacto@speakland.com.mx" className="footer-email">contacto@speakland.com.mx</a>
        </div>
        <div className="footer-column">
          <h2>Cursos</h2>
          <ul>
            <li><Link to="/courses#kidsland">Kidsland</Link></li>
            <li><Link to="/courses#kidsland">Teens</Link></li>
            <li><Link to="/courses#inglestradicional">Inglés Tradicional</Link></li>
            <li><Link to="/courses#personalizado">Personalizado 1-1</Link></li>
            <li><Link to="/courses#ejecutivo">Ejecutivo</Link></li>
            <li><Link to="/courses#asesorias">Asesorías</Link></li>
          </ul>
        </div>
        <div className="footer-column">
          <h2>Menú</h2>
          <ul>
            <li><Link to="/">Inicio</Link></li>
            <li><Link to="/staff">Staff</Link></li>
            <li><Link to="/bolsatrabajo">Bolsa de trabajo</Link></li>
            <li><Link to="/prueba">Exam</Link></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-logo">
          <img src={`${publicUrl}/media/logo.png`} alt="Speakland Logo" />
        </div>
        <div className="footer-center">
          <p>© Speakland 2024. All Rights Reserved.</p>
        </div>
        <div className="footer-social">
          <a href="https://api.whatsapp.com/send?phone=5216681931693&text=Hola%2C%20quiero%20informaci%C3%B3n%20acerca%20de%20los%20cursos" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
            <img src={`${publicUrl}/media/logo/wpp.png`} alt="WhatsApp" />
          </a>
          <a href="https://www.facebook.com/SpeaklandLanguageCenter" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <img src={`${publicUrl}/media/logo/fb.png`} alt="Facebook" />
          </a>
          <a href="https://www.tiktok.com/@speaklandlosmochis" target="_blank" rel="noopener noreferrer" aria-label="TikTok">
            <img src={`${publicUrl}/media/logo/tk.png`} alt="TikTok" />
          </a>
          <a href="tel:+16682390450" target="_blank" rel="noopener noreferrer" aria-label="Phone">
            <img src={`${publicUrl}/media/logo/phone.png`} alt="Phone" />
          </a>
          <a href="https://www.instagram.com/speaklandoficial/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <img src={`${publicUrl}/media/logo/ing.png`} alt="Instagram" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
