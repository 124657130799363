import React, { useEffect, useState } from 'react';
import '../styles/CountdownTimer.css'; // Asegúrate de que el archivo CSS esté importado

const CountdownTimer = ({ onCountdownEnd }) => {
    const [count, setCount] = useState(3);
    const [showGO, setShowGO] = useState(false); // Estado para manejar el "GO!"
    const [showReady, setShowReady] = useState(true); // Estado para manejar el "READY?"
    const [audio] = useState(new Audio(`${process.env.PUBLIC_URL}/media/race-start-beeps-125125.ogg`));

    useEffect(() => {
        const playAudio = () => {
            audio.volume = 1.0; // Ajusta el volumen si es necesario
            audio.play().catch(error => {
                console.log('Error al reproducir el audio:', error);
            });
        };

        playAudio();

        const timerId = setInterval(() => {
            setCount(prevCount => {
                if (prevCount > 1) {
                    return prevCount - 1;
                } else if (prevCount === 1) {
                    clearInterval(timerId);
                    setShowReady(false); // Oculta "READY?" cuando el contador llega a 1
                    setShowGO(true); // Muestra "GO!"
                    setTimeout(() => {
                        setShowGO(false); // Oculta "GO!"
                        if (onCountdownEnd) onCountdownEnd(); // Llama a la función cuando el contador llegue a 0
                    }, 1000); // Muestra "GO!" por 1 segundo
                    return prevCount;
                }
                return prevCount;
            });
        }, 1000);

        return () => {
            clearInterval(timerId);
            audio.pause(); // Pausa el audio si el componente se desmonta
            audio.currentTime = 0; // Reinicia el tiempo de reproducción
        };
    }, [audio, onCountdownEnd]);

    return (
        <div className="countdown-container">
            {showReady && <div className="countdown-message">READY?</div>}
            {!showGO && <div className="countdown-timer">{count}</div>}
            {showGO && <div className="countdown-go">GO!</div>}
        </div>
    );
};

export default CountdownTimer;
