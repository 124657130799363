import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    // Verifica si la ruta actual NO incluye las secciones "certificaciones" o "contacto"
    if (location.pathname === '/') {
      // Si estamos en la página principal, no reiniciamos el scroll (dejamos que el comportamiento de anclaje funcione)
      return;
    }

    // Para cualquier otra ruta, forzar el scroll al inicio de la página
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

export default ScrollToTop;
