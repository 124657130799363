import React, { useState, useEffect } from 'react';
import '../styles/CoursesSection.css';
import { useLocation } from 'react-router-dom';

const publicUrl = process.env.PUBLIC_URL;

const CoursesSection = () => {
  const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
  const [modalAbierto, setModalAbierto] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        // Desplázate suavemente al elemento con el ID del hash
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        
        // Ajuste adicional del desplazamiento para compensar posibles encabezados fijos o desplazamientos inesperados
        const yOffset = -100; // Ajusta este valor según la altura de tu encabezado
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const coursesSection = document.querySelector('.courses-section');
      if (coursesSection) {
        const top = coursesSection.getBoundingClientRect().top;
        const isVisible = top < window.innerHeight && top > 0;
        if (!isVisible) {
          console.log('Sección de cursos no visible en el viewport');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const abrirModal = (curso) => {
    setCursoSeleccionado(curso);
    setModalAbierto(true);
  };

  const cerrarModal = () => {
    setModalAbierto(false);
    setCursoSeleccionado(null);
  };

  const ModalCurso = ({ curso, onClose }) => {
    if (!curso) return null;
    return (
      <div className="curso-modal" onClick={onClose}>
        <div className="curso-modal-content" onClick={(e) => e.stopPropagation()}>
          <h2>{curso.title}</h2>
          <p>{curso.description}</p>
          <button onClick={onClose}>Cerrar</button>
        </div>
      </div>
    );
  };

  return (
    <div className="courses-section">
      <div className="header-container" data-aos="zoom-in">
        <img
          src={`${publicUrl}/media/encabezados/cursos.png`}
          alt="Speakfest 2024"
          className="header-image"
        />
      </div>

      <div className="course-card colores-effect" data-aos="fade-up" id="kidsland">
        <div className="image-container">
          <img
            className="course-image"
            src={`${publicUrl}/media/video/1.png`}
            alt="KIDSLAND"
          />
          <div className="overlay">
            <a
              href={`https://api.whatsapp.com/send?phone=5216681931693&text=${encodeURIComponent('Hola, quiero información acerca de Kidsland')}`}
              className="overlay-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="overlay-text">Consultar precios</span>
            </a>
          </div>
        </div>
        <div className="course-info" data-aos="fade-left">
          <h2 className="colores-effect">
            <span className="letter" style={{ color: '#FF0000' }}>K</span>
            <span className="letter" style={{ color: '#FF7F00' }}>I</span>
            <span className="letter" style={{ color: '#6F4A3C' }}>D</span>
            <span className="letter" style={{ color: '#00FF00' }}>S</span>
            <span className="letter" style={{ color: '#0000FF' }}>L</span>
            <span className="letter" style={{ color: '#4B0082' }}>A</span>
            <span className="letter" style={{ color: '#9400D3' }}>N</span>
            <span className="letter" style={{ color: '#FF0000' }}>D</span>
          </h2>
          <h3>Inglés para Niños</h3>
          <p>En nuestro programa para niños (kidsland) ofrecemos un espacio innovador para que nuestros alumnos incrementen su dominio del idioma inglés con seguridad, venciendo los miedos a cometer errores a una temprana edad.</p>
          <span
            className="more-info-link"
            onClick={() => abrirModal({
              title: 'KIDSLAND',
              description: 'En nuestro programa para niños (kidsland) ofrecemos un espacio innovador para que nuestros alumnos incrementen su dominio del idioma inglés con seguridad, venciendo los miedos a cometer errores a una temprana edad.'
            })}
          >
            Saber más…
          </span>
        </div>
      </div>

      <div className="course-card speed-effect" data-aos="fade-up" id="inglestradicional">
        <div className="image-container">
          <img
            className="course-image"
            src={`${publicUrl}/media/video/2.png`}
            alt="INGLÉS TRADICIONAL"
          />
          <div className="overlay">
            <a
              href={`https://api.whatsapp.com/send?phone=5216681931693&text=${encodeURIComponent('Hola, quiero información acerca de inglés Tradicional')}`}
              className="overlay-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="overlay-text">Consultar precios</span>
            </a>
          </div>
        </div>
        <div className="course-info" data-aos="fade-left">
          <h2>INGLÉS TRADICIONAL</h2>
          <h3>Presencial</h3>
          <p>Nuestro programa tradicional está diseñado para fomentar el gusto por el inglés en personas de todas las edades, derribando las barreras del miedo o la desconfianza a hablarlo con fluidez.</p>
          <span
            className="more-info-link"
            onClick={() => abrirModal({
              title: 'INGLÉS TRADICIONAL',
              description: 'Nuestro programa tradicional está diseñado para fomentar el gusto por el inglés en personas de todas las edades, con un buen dominio del idioma y derribando las barreras del miedo o la desconfianza a hablarlo con fluidez.'
            })}
          >
            Saber más…
          </span>
        </div>
      </div>

      <div className="course-card neon-effect" data-aos="fade-up" id="personalizado">
        <div className="image-container">
          <img
            className="course-image"
            src={`${publicUrl}/media/video/3.png`}
            alt="PERSONALIZADO 1 – 1"
          />
          <div className="overlay">
            <a
              href={`https://api.whatsapp.com/send?phone=5216681931693&text=${encodeURIComponent('Hola, quiero información acerca de Personalizado 1 – 1')}`}
              className="overlay-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="overlay-text">Consultar precios</span>
            </a>
          </div>
        </div>
        <div className="course-info" data-aos="fade-left">
          <h2>PERSONALIZADO 1 – 1</h2>
          <h3>Intensivo</h3>
          <p>En nuestro servicio personalizado/VIP diseñamos un plan intensivo de forma más individual en base a las necesidades personales, profesionales o académicas del solicitante; con el fin de lograr objetivos a un corto – mediano plazo.</p>
          <span
            className="more-info-link"
            onClick={() => abrirModal({
              title: 'PERSONALIZADO 1 – 1',
              description: 'En nuestro servicio personalizado/VIP diseñamos un plan intensivo de forma más individual en base a las necesidades personales, profesionales o académicas del solicitante; con el fin de lograr objetivos a un corto – mediano plazo.'
            })}
          >
            Saber más…
          </span>
        </div>
      </div>

      <div className="course-card melon-effect" data-aos="fade-up" id="ejecutivo">
        <div className="image-container">
          <img
            className="course-image"
            src={`${publicUrl}/media/video/4.png`}
            alt="EJECUTIVO"
          />
          <div className="overlay">
            <a
              href={`https://api.whatsapp.com/send?phone=5216681931693&text=${encodeURIComponent('Hola, quiero información acerca de Ejecutivo')}`}
              className="overlay-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="overlay-text">Consultar precios</span>
            </a>
          </div>
        </div>
        <div className="course-info" data-aos="fade-left">
          <h2>EJECUTIVO</h2>
          <h3>VIP</h3>
          <p>La misión de nuestros cursos ejecutivos es cubrir las necesidades requeridas exclusivamente por profesionistas; con la intención de lograr objetivos más especializados. Por lo tal, facilitamos herramientas de la comunicación efectiva usando el idioma inglés de acuerdo a un área laboral específica para alumnos o una empresa en particular.</p>
          <span
            className="more-info-link"
            onClick={() => abrirModal({
              title: 'EJECUTIVO',
              description: 'La misión de nuestros cursos ejecutivos es cubrir las necesidades requeridas exclusivamente por profesionistas; con la intención de lograr objetivos más especializados. Por lo tal, facilitamos herramientas de la comunicación efectiva usando el idioma inglés de acuerdo a un área laboral específica para alumnos o una empresa en particular.'
            })}
          >
            Saber más…
          </span>
        </div>
      </div>

      <div className="course-card melon-effect" data-aos="fade-up" id="asesorias">
        <div className="image-container">
          <img
            className="course-image"
            src={`${publicUrl}/media/video/5.png`}
            alt="ASESORÍAS"
          />
          <div className="overlay">
            <a
              href={`https://api.whatsapp.com/send?phone=5216681931693&text=${encodeURIComponent('Hola, quiero información acerca de Asesorías')}`}
              className="overlay-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="overlay-text">Consultar precios</span>
            </a>
          </div>
        </div>
        <div className="course-info" data-aos="fade-left">
          <h2>ASESORÍAS</h2>
          <h3>Acompañamiento</h3>
          <p>Nuestro servicio de asesorías y tutorías está intencionado para todos aquellos estudiantes que busquen recibir acompañamiento adicional; así sea, la realización de proyectos y tareas escolares, preparación para concursos o acompañamiento para exámenes.</p>
          <span
            className="more-info-link"
            onClick={() => abrirModal({
              title: 'ASESORÍAS',
              description: 'Nuestro servicio de asesorías y tutorías está intencionado para todos aquellos estudiantes que busquen recibir acompañamiento adicional; así sea, la realización de proyectos y tareas escolares, preparación para concursos o acompañamiento para exámenes.'
            })}
          >
            Saber más…
          </span>
        </div>
      </div>

      {modalAbierto && (
        <ModalCurso curso={cursoSeleccionado} onClose={cerrarModal} />
      )}
    </div>
  );
};

export default CoursesSection;
