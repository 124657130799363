// src/components/WhatsAppButton.js
import React from 'react';
import '../styles/WhatsAppButton.css'; // Asegúrate de que este archivo CSS exista en la misma carpeta

const WhatsAppButton = () => {
  const message = 'Hola, me interesa saber más sobre Speakland';
  const whatsappUrl = `https://api.whatsapp.com/send?phone=5216681931693&text=${encodeURIComponent(message)}`;

  return (
    <a
      href={whatsappUrl}
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={`${process.env.PUBLIC_URL}/media/whatsapp.png`}
        alt="WhatsApp"
        className="whatsapp-icon"
      />
    </a>
  );
};

export default WhatsAppButton;
